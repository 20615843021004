
import React, { Fragment, useEffect, useState } from "react";
import { MainContainer } from "./style";
import { AdminCancelTransData, AdminFullTransData, AdminHistoricalTransData, ECLoginState, ESPlanId, EShopId } from "@store/auth/interfaces";
import { useAppSelector, useAppDispatch } from "@store/index";
import { } from "@store/auth";
import { getAdminTransStatistic } from "@src/ApiFunctions/statFunc";
import { useNavigate } from "react-router-dom";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { formatDuration } from 'date-fns';
import { DataGrid, GridColDef, GridComparatorFn, GridValueGetterParams } from '@mui/x-data-grid';
import { Typography } from "@mui/material";
import { LineChart, XAxis, YAxis, Tooltip, CartesianGrid, Line } from "recharts";
import { dateComparator, customFormatInTimeZone } from "@src/helpers";


const HistoricalSubs = () => {
  const siteState = useAppSelector((state) => state.auth);
  const [statData, setStatData] = useState<AdminHistoricalTransData[]>([]);
  const [filteredData, setFilteredData] = useState<AdminHistoricalTransData[]>([]);
  const [showTest, setShowTest] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (siteState.loginState === ECLoginState.LoggedIn) {
      getAdminTransStatistic("HistoricalSubsStat", siteState.loginToken!).then(result => {
        console.log(result)
        if (result != null) {
          setStatData(result as []);
        }

      })
    } else {
      navigate('/');
    }
  }, [])


  useEffect(() => {
    const filterData = () => {
      setFilteredData(statData.filter(t =>
        t.is_test === showTest))
    }
    filterData();
  }, [showTest,statData])

  const columns: GridColDef[] = [
    {
      field: 'Date', headerName: 'Date', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.Date)}`
    },
    { field: 'CHFSum', headerName: 'CHF monthly', flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
    `${(params.row.CHFSum/params.row.CHFMonth).toFixed(2)}` },
    { field: 'Week', headerName: 'Weekly Count',   flex: 1 },
    { field: 'Monthly', headerName: 'Monthly Count', flex: 1 },
    { field: 'HalfYear', headerName: 'HalfYear Count',   flex: 1 },
    { field: 'Year', headerName: 'Year Count', flex: 1 },
    { field: 'Trial', headerName: 'Trial count', flex: 1 },
  ];

  return <MainContainer>
    {!statData || statData.length == 0 ?
      <div>Loading</div>
      : <Fragment>
        <Paper>
          <FormGroup row={true} style={{ marginBottom: "10px" }}>
            <FormControlLabel control={<Checkbox
              checked={showTest}
              onChange={(event, checked) => setShowTest(checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label="Тестовые" />
          </FormGroup>
        </Paper>
        <DataGrid
          density={'compact'}
          rowSelection={false}
          rows={filteredData}
          rowHeight={70}
          columns={columns}
          getRowId={(row: AdminHistoricalTransData) => { return row.id; }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100, 1000]}
        />

          <Typography style={{ marginBottom: "10px",marginTop:"10px",fontSize:"20px",fontWeight:"bold" }}>Граф Weekly</Typography>
          <LineChart
            width={800}
            height={400}
            data={ [...filteredData].reverse()}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="Date" tickFormatter={(label) => { return label.replace(".000Z", "").replace("T00:00:00", "") }}  />
            <YAxis type="number" dataKey={(v)=>parseInt(v.Week)}  domain={[0,'dataMax+5']}  />
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line type="monotone" dataKey="Week" dot={false} stroke="#ADD8E6" yAxisId={0} />
          </LineChart>
          <Typography style={{ marginBottom: "10px",marginTop:"10px",fontSize:"20px",fontWeight:"bold" }}>Граф Monthly</Typography>
          <LineChart
            width={800}
            height={400}
            data={ [...filteredData].reverse()}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="Date" tickFormatter={(label) => { return label.replace(".000Z", "").replace("T00:00:00", "") }}  />
            <YAxis type="number" dataKey={(v)=>parseInt(v.Monthly)}  domain={[0,'dataMax+5']} />
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line type="monotone" dataKey="Monthly" dot={false} stroke="#00FF00" yAxisId={0} />
          </LineChart>
          <Typography style={{ marginBottom: "10px",marginTop:"10px",fontSize:"20px",fontWeight:"bold" }}>Граф HalfYear</Typography>
          <LineChart
            width={800}
            height={400}
            data={ [...filteredData].reverse()}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="Date" tickFormatter={(label) => { return label.replace(".000Z", "").replace("T00:00:00", "") }}  />
            <YAxis type="number" dataKey={(v)=>parseInt(v.HalfYear)}  domain={[0,'dataMax+5']}  />
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line type="monotone" dataKey="HalfYear" dot={false} stroke="#ADD8E6" yAxisId={0} />
          </LineChart>
          <Typography style={{ marginBottom: "10px",marginTop:"10px",fontSize:"20px",fontWeight:"bold" }}>Граф Year</Typography>
          <LineChart
            width={800}
            height={400}
            data={ [...filteredData].reverse()}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="Date" tickFormatter={(label) => { return label.replace(".000Z", "").replace("T00:00:00", "") }}  />
            <YAxis type="number" dataKey={(v)=>parseInt(v.Year)}  domain={[0,'dataMax+5']}/>
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line type="monotone" dataKey="Year" dot={false} stroke="#FFD700" yAxisId={0} />
          </LineChart>
          <Typography style={{ marginBottom: "10px",marginTop:"10px",fontSize:"20px",fontWeight:"bold" }}>Граф Trial</Typography>
          <LineChart
            width={800}
            height={400}
            data={ [...filteredData].reverse()}
            margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="Date" tickFormatter={(label) => { return label.replace(".000Z", "").replace("T00:00:00", "") }}  />
            <YAxis type="number" dataKey={(v)=>parseInt(v.Trial)}  domain={[0,'dataMax+5']}  />
            <Tooltip />
            <CartesianGrid stroke="#f5f5f5" />
            <Line type="monotone" dataKey="Trial" dot={false} stroke="#000000" yAxisId={0} />
          </LineChart>
      </Fragment>}
  </MainContainer>;

};

export default HistoricalSubs;



import { AdminGeneralDayStat, AdminStatisticActiveSubscriptions, DasboardStatistic } from "@store/auth/interfaces";

export const getAdminTransStatistic =
    async (statType: string, tokenValue: string) => {
        try {
            if ((!tokenValue || tokenValue == "")) {
                return;
            }
            var response: any = await fetch(' https://1d8nfr00e4.execute-api.eu-west-2.amazonaws.com/master/Admin/AdminStats', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'admin_insta_jwttoken': tokenValue ?? "error"
                },
                body: JSON.stringify({
                    statType
                }),
            })
            var responseData: { data: object[], statusCode: number } = await response.json();
            console.log(statType, responseData);
            if (responseData.statusCode == 200) {
                return responseData.data;
            }
            else {
                alert("responseData="+responseData);
                return null;
            }

        } catch (e) {
            // error reading value
            console.log(e);
            alert(e);
            return null;
        }
    }

export const getDasboardStatistic =
    async ( tokenValue: string): Promise<DasboardStatistic|undefined> => {
        try {
            if ((!tokenValue || tokenValue == "")) {
                return;
            }
            var response: any = await fetch(' https://1d8nfr00e4.execute-api.eu-west-2.amazonaws.com/master/Admin/AdminGeneralStats', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'admin_insta_jwttoken': tokenValue ?? "error"
                },
                body: JSON.stringify({
                }),
            })
            var responseData: {generalData: { data: AdminGeneralDayStat[], statusCode: number },
            general_activeData: { data: AdminStatisticActiveSubscriptions[], statusCode: number }} = await response.json();
            console.log(responseData);
            if (responseData.generalData.statusCode == 200) {
                return {generalData:responseData.generalData.data ,
                    general_activeData:responseData.general_activeData.data} as DasboardStatistic;
            }
            else {
                alert("responseData="+responseData);
                return undefined;
            }

        } catch (e) {
            // error reading value
            console.log(e);
            alert(e);
            return undefined;
        }
    }

export const sendNewTestUsers =
    async (emailList: string[], tokenValue: string): Promise<boolean> => {
        try {
            if ((!tokenValue || tokenValue == "")) {
                return false;
            }
            var response: any = await fetch(' https://1d8nfr00e4.execute-api.eu-west-2.amazonaws.com/master/Admin/AddNewTestUsers', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'admin_insta_jwttoken': tokenValue ?? "error"
                },
                body: JSON.stringify({
                    emailList
                }),
            })
            var responseData: { statusCode: number } = await response.json();
            console.log(responseData);
            if (responseData.statusCode == 200) {
                return true;
            }
            else {
                alert("responseData="+responseData);
                return false;
            }

        } catch (e) {
            // error reading value
            console.log(e);
            alert(e);
            return false;
        }
    }

export const getAdminUserActivity =
    async (userId: string, tokenValue: string)=> {
        try {
            if ((!tokenValue || tokenValue == "")) {
                return null;
            }
            var response: any = await fetch(' https://1d8nfr00e4.execute-api.eu-west-2.amazonaws.com/master/Admin/AdminUserActivity', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'admin_insta_jwttoken': tokenValue ?? "error"
                },
                body: JSON.stringify({
                    userId
                }),
            })
            var responseData: { responceCode: number, data: object } = await response.json();
            console.log(responseData);
            if (responseData.responceCode == 1) {
                return responseData.data;
            }
            else {
                alert("responseData=" + responseData);
                return null;
            }

        } catch (e) {
            // error reading value
            console.log(e);
            alert(e);
            return null;
        }
    }

export const generateGuestToken =
    async (uuid: string, tokenValue: string): Promise<string> => {
        try {
            if ((!tokenValue || tokenValue == "")) {
                return "";
            }
            var response: any = await fetch(' https://1d8nfr00e4.execute-api.eu-west-2.amazonaws.com/master/Admin/GenerateGuestToken', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'admin_insta_jwttoken': tokenValue ?? "error"
                },
                body: JSON.stringify({
                    uuid
                }),
            })
            var responseData: { responceCode: number, data: string } = await response.json();
            console.log(responseData);
            if (responseData.responceCode == 1) {
                return responseData.data;
            }
            else {
                alert("responseData=" + responseData);
                return "";
            }

        } catch (e) {
            // error reading value
            console.log(e);
            alert(e);
            return "";
        }
    }

export const addFreeSubscription =
    async (email: string,planId:number, tokenValue: string): Promise<boolean> => {
        try {
            if ((!tokenValue || tokenValue == "")) {
                return false;
            }
            var response: any = await fetch(' https://1d8nfr00e4.execute-api.eu-west-2.amazonaws.com/master/Admin/AddFreeSubscription', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'admin_insta_jwttoken': tokenValue ?? "error"
                },
                body: JSON.stringify({
                    email,
                    planId
                }),
            })
            var responseData: { statusCode: number } = await response.json();
            console.log(responseData);
            if (responseData.statusCode == 200) {
                return true;
            }
            else {
                alert("responseData="+responseData);
                return false;
            }

        } catch (e) {
            // error reading value
            console.log(e);
            alert(e);
            return false;
        }
    }
import { GridComparatorFn } from "@mui/x-data-grid";
import { parse as dateParse, parseISO as dateISOParse } from "date-fns";
import { format as dateFormat, utcToZonedTime } from "date-fns-tz";

export const dateComparator: GridComparatorFn = (v1, v2, cellParams1, cellParams2) => {
  const date1 = dateParse(cellParams1.value, 'HH:mm dd-MM-yyyy', new Date());
  const date2 = dateParse(cellParams2.value, 'HH:mm dd-MM-yyyy', new Date());
  console.log(cellParams1, cellParams2)
  if (date1 < date2) {
    return -1;
  } else if (date1 > date2) {
    return 1;
  } else {
    return 0;
  }
};

export const dateCombinedComparator: GridComparatorFn = (v1, v2, cellParams1, cellParams2) => {
  const parsed1=cellParams1.value.split(" - ")[0];
  const parsed2=cellParams2.value.split(" - ")[0];
  const date1 = dateParse(parsed1, 'HH:mm dd-MM-yyyy', new Date());
  const date2 = dateParse(parsed2, 'HH:mm dd-MM-yyyy', new Date());
  
  if (date1 < date2) {
    return -1;
  } else if (date1 > date2) {
    return 1;
  } else {
    return 0;
  }
};


export const customFormatInTimeZone = (date: any) =>
dateFormat(utcToZonedTime(dateISOParse(date), "UTC"),
'HH:mm dd-MM-yyyy',
  { timeZone: "UTC" });
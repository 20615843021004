import styled from '@emotion/styled';
import { Paper, Grid, Typography, TextField, Button } from '@mui/material';

export const LoginContainer = styled(Grid)`
color: 'white';
padding: 15;
flex: 1;
margin-top: 15;
flex-direction:column;
  `
export const LoginImg = styled.img`
    margin-right:auto;
    margin-top:40px;
    margin-bottom:20px;
    margin-left:auto;
`
export const VerifImg = styled.img`
    margin-right:auto;
    margin-top:40px;
    margin-bottom:20px;
    margin-left:auto;
`

export const Title = styled(Typography)`
    font-size:36px;
    margin-bottom:10px;
    text-align:center;
    font-weight:bold;
    @media screen and (max-width: 767px) {
        font-size:24px;
    }
`
export const SubTitle = styled(Typography)`
    font-size:24px;
    margin-bottom:15px;
    text-align:center;
    @media screen and (max-width: 767px) {
        font-size:20px;
    }
`
export const ErrorText = styled(Typography)`
font-size:20px;
color:red;
margin-bottom:15px;
text-align:center;
@media screen and (max-width: 767px) {
    font-size:16px;
}
`
export const LinkText = styled(Typography)`
    font-size:20px;
    color:lime;
    @media screen and (max-width: 767px) {
        font-size:16px;
    }
`
export const InputField = styled(TextField)`
    padding:10px;
`
export const MainButton = styled(Button)`
    margin-bottom:20px;
    padding:15px 30px;
    min-width:200px;
    color:white;
    font-size: 16px;
    background: #FF6C99;
    border-radius: 8px;
    margin-top:15px;
    :disabled{
        background: #D7D7D7;
        border-radius: 8px;
        color:color: #737373;
    }
    :hover, :focus{
        background: #FF3F7A;
        border-radius: 8px;
    }
`

import React, { Fragment, useEffect, useState } from "react";
import { MainContainer } from "./style";
import { AdminFullTransData, ECLoginState } from "@store/auth/interfaces";
import { useAppSelector, useAppDispatch } from "@store/index";
import { } from "@store/auth";
import { getAdminTransStatistic } from "@src/ApiFunctions/statFunc";
import { useNavigate } from "react-router-dom";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { formatDuration } from 'date-fns';
import { dateComparator, customFormatInTimeZone } from "@src/helpers";
import { DataGrid, GridColDef, GridComparatorFn, GridValueGetterParams } from '@mui/x-data-grid';


const CurrentTrans = () => {
  const siteState = useAppSelector((state) => state.auth);
  const [statData, setStatData] = useState<AdminFullTransData[]>([]);
  const [filteredData, setFilteredData] = useState<AdminFullTransData[]>([]);
  const [showTest, setShowTest] = useState<boolean>(true);
  const [showTrial, setShowTrial] = useState<boolean>(true);
  const [showFirst, setShowFirst] = useState<boolean>(true);
  const [showRepeat, setShowRepeat] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (siteState.loginState === ECLoginState.LoggedIn) {
      getAdminTransStatistic("CurrentTrans", siteState.loginToken!).then(result => {
        console.log(result)
        if (result != null) {
          setStatData(result as AdminFullTransData[]);
        }

      })
    } else {
      navigate('/');
    }
  }, [])


  useEffect(() => {
    filterData();
  }, [showTest, showTrial, showFirst, showRepeat, statData])
  const filterData = () => {
    setFilteredData(statData.filter(t =>
      (showTest || (t.environment != "sandbox" && !t.is_test)) &&
      (showTrial || t.planName != "Trial") &&
      (showFirst || t.IsFirst != true) &&
      (showRepeat || t.IsRepeat != true)))
  }



  const columns: GridColDef[] = [
    { field: 'TransId', type: 'number', headerName: 'TransId', flex: 0 },
    { field: 'vpnusername', headerName: 'VPN username', width: 50, sortable: false, flex: 2, },
    { field: 'language', headerName: 'Lng', flex: 0 },
    { field: 'userId', headerName: 'User ID', flex: 0 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 },
    { field: 'planName', headerName: 'Plan', flex: 1 },
    {
      field: 'PrevPlan', headerName: 'PrevPlan', flex: 0,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.PrevPlan ?? "N/A"}`
    },
    {
      field: 'DateTimeBuy', headerName: 'DateTimeBuy', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.DateTimeBuy)}`
    },
    {
      field: 'DatimeReg', headerName: 'DatimeReg',
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.DatimeReg)}`
    },
    {
      field: 'TimeToBuy', headerName: 'TimeToBuy', flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${formatDuration(params.row.TimeToBuy)}`
    },

    {
      field: 'OS', headerName: 'OS', sortable: false, flex: 0,
      valueGetter: (params: GridValueGetterParams) =>
        `No data`
    },
    { field: 'paymentSystem', headerName: 'Payment system', flex: 0 },
    { field: 'promocode', headerName: 'PromoCode', flex: 0 },
    { field: 'Price', type: 'number', headerName: 'Price', flex: 0 },
    { field: 'Currency', headerName: 'Currency', flex: 0 },
  ];

  return <MainContainer>
    {!statData || statData.length == 0 ?
      <div>Loading</div>
      : <Fragment>
        <Paper>
          <FormGroup row={true} style={{ marginBottom: "10px" }}>
            <FormControlLabel control={<Checkbox
              checked={showTest}
              onChange={(event, checked) => setShowTest(checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label="Тестовые" />
            <FormControlLabel control={<Checkbox
              checked={showTrial}
              onChange={(event, checked) => setShowTrial(checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label="Триалы" />
            <FormControlLabel control={<Checkbox
              checked={showFirst}
              onChange={(event, checked) => setShowFirst(checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label="Первые" />
            <FormControlLabel control={<Checkbox
              checked={showRepeat}
              onChange={(event, checked) => setShowRepeat(checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label="Повторные" />
          </FormGroup>
        </Paper>
        <DataGrid
          density={'compact'}
          rowSelection={false}
          rows={filteredData}
          rowHeight={70}
          columns={columns}
          getRowId={(row: AdminFullTransData) => { return row.TransId; }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100, 1000]}
        />
      </Fragment>}
  </MainContainer>;

};

export default CurrentTrans;


import React, { Fragment, useEffect, useState } from "react";
import { MainContainer } from "./style";
import { AdminFutureFullTransData, ECLoginState } from "@store/auth/interfaces";
import { useAppSelector, useAppDispatch } from "@store/index";
import { } from "@store/auth";
import { useNavigate } from "react-router-dom";
import { getAdminTransStatistic } from "@src/ApiFunctions/statFunc";
//import { format as dateFormat }  from "date-fns";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { customFormatInTimeZone, dateComparator } from "@src/helpers";



const FutureTrans = () => {
  const siteState = useAppSelector((state) => state.auth);
  const [statData, setStatData] = useState<AdminFutureFullTransData[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (siteState.loginState === ECLoginState.LoggedIn) {
      getAdminTransStatistic("FutureTrans", siteState.loginToken!).then(result => {
        if (result != null) {
          setStatData(result as AdminFutureFullTransData[]);
        }

      })
    } else {
      navigate('/');
    }
  }, [])

  const columns: GridColDef[] = [
    { field: 'TransId', type: 'number', headerName: 'Sub Id', flex: 0 },
    { field: 'vpnusername', headerName: 'VPN username', width: 50, sortable: false, flex: 2, },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 },
    { field: 'CurrentPlan', headerName: 'CurrentPlan', flex: 1 },
    { field: 'convertedSum', headerName: 'CHF', flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
    `${params.row.CurrentPlan<7?(Number(params.row.convertedSum)/Number(params.row.monthCount)).toFixed(2):('0.00')}` },
    { field: 'NextPlan', headerName: 'FuturePlan', flex: 1 },
    {
      field: 'dateStart', headerName: 'CurrentSubsStart', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.dateStart)}`
    },
    {
      field: 'dateEnd', headerName: 'Reccuring Date', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.dateEnd)}`
    },
    { field: 'paymentSystem', headerName: 'Payment system', flex: 0 },
    { field: 'promocode', headerName: 'PromoCode', flex: 0 },
  ];

  return <MainContainer>
    {!statData || statData.length == 0 ?
      <div>Loading</div>
      : <Fragment>

        <DataGrid
          density={'compact'}
          rowSelection={false}
          rows={statData}
          rowHeight={70}
          columns={columns}
          getRowId={(row: AdminFutureFullTransData) => { return row.TransId; }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
            sorting: {
              sortModel: [{
                field: "dateEnd",
                sort: "asc"
              }]
            }
          }}
          pageSizeOptions={[5, 10, 50, 100, 1000]}
        />
      </Fragment>}
  </MainContainer>;

};

export default FutureTrans;


import { Grid, Typography, Button, TextField } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { systemLogin } from '../../../store/auth';
import { LoginContainer, LoginImg, SubTitle, Title, MainButton, VerifImg } from './style';


const LoginScreen = () => {
  const [email, changeEmail] = useState<string>("");
  const [verifMode, changeVerifMode] = useState<boolean>(false);
  const [password, changePassword] = useState<string>("");
  const [error, changeErrorState] = useState<boolean>(false);
  const [pressed, changePressedState] = useState<boolean>(false);
  const siteState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    changeErrorState(false);
  }, [email, password])


  const Login = () => {
    if (pressed) {
      return;
    }
    if (email == "" || email.length < 3) {
      changeErrorState(true);
      return;
    }

    changePressedState(true);
    return fetch(' https://1d8nfr00e4.execute-api.eu-west-2.amazonaws.com/master/Admin/AdminLogin', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'insta_jwttoken': siteState.loginToken ?? "error"
      },
      body: JSON.stringify({
        email: email
      }),
    })
      .then(response => response.json())
      .then(async json => {
        console.log(json);
        changePressedState(false);
        if (json.responceCode) {
          switch (json.responceCode) {
            case 0:
              await dispatch(systemLogin({ loginToken: siteState.loginToken!, email: email }))
              break;
            case 1:
              changeVerifMode(true);
              break;
            default:
              changeErrorState(true);
              return;
          }
        }

      })
      .catch(error => {
        changePressedState(false);
        console.error(error);
      });
  }

  const VerifPassword = () => {
    if (pressed) {
      return;
    }

    if (password == "" || password.length < 3) {
      changeErrorState(true);
      return;
    }
    changePressedState(true);
    return fetch(' https://1d8nfr00e4.execute-api.eu-west-2.amazonaws.com/master/Admin/AdminVerifyCode', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'insta_jwttoken': siteState.loginToken ?? "error"
      },
      body: JSON.stringify({
        email: email,
        code: password
      }),
    })
      .then(response => response.json())
      .then(async json => {
        console.log(json);
        changePressedState(false);
        if (json.responceCode) {
          switch (json.responceCode) {
            case 0:
              await dispatch(systemLogin({ loginToken: siteState.loginToken!, email: email }))
              break;
            case 1:
              await dispatch(systemLogin({ loginToken: json.token, email: email }))
              break;
            default:
              changeErrorState(true);
              return;
          }
        }

      })
      .catch(error => {
        changePressedState(false);
        console.error(error);
      });
  }
  const validateInput = () => {
    return !((!verifMode && email != "" && email.length > 3) || (verifMode && password != "" && password.length > 3))
  }

 const ContinueButtonFunc=()=>{
    if (verifMode){
      VerifPassword();
    }
    else
    {
      Login();
    }
  }

  return (
    <Grid>

      <LoginContainer container direction="row">
        <LoginImg src={require('@images/LoginImage.png')} />
        <Title >Авторизация</Title >
      
        {!verifMode
          ? <Fragment>
              <SubTitle >Для входа введите вашу почту</SubTitle >
            {email != "" &&
              <Typography >Почта</Typography >}

            <TextField
              error={error}
              onChange={(event) => { changeEmail(event.target.value) }}
              value={email.length == 0 ? "" : email}
              placeholder="Почта"
              type='email'

            />
          </Fragment>
          : <Fragment>
               <SubTitle >Для входа введите ваш код с почты</SubTitle >
            {password != "" &&
              <Typography >Пароль</Typography >}
            <TextField
              error={error}
              onChange={(event) => { changePassword(event.target.value) }}
              value={password.length == 0 ? "" : password}
              placeholder="Пароль"
              type='text'
            />
          </Fragment>}
        <MainButton onTouchEnd={ContinueButtonFunc}
          onClick={ContinueButtonFunc} disabled={validateInput()} variant="contained">
          <Typography
          >Продолжить</Typography >
        </MainButton>
      </LoginContainer>
    </Grid>
  );
};



export default LoginScreen;

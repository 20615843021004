import React from "react";
import {useRoutes } from "react-router-dom";

import { mainRoutes, accountRoutes } from "./routes";




const App: React.FC = (): JSX.Element => {
  console.log('Start')
  const routing = useRoutes([mainRoutes, accountRoutes]);
  return <>{routing}</>
};


export default App;

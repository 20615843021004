import React from "react";
import { Container, Button, Box, Toolbar, AppBar } from "@mui/material";
import { useLocation } from "react-router-dom";
import logo from "@images/main_icon.png"
import { LinkCustom } from "./style";

type Props = {
  children?: React.ReactNode;
};
const pages = ['Dasboard', 'CurrentTrans', 'FutureTrans', 'TestUsers', 'FreeUsers', 'Users', 'UserBehaviour', 'AdminTools', 'CanceledTrans','HistoricalSubs'];

const AppBar_Cabinet = ({ children }: Props) => {
  const location = useLocation();

  console.log(location.pathname)

  return <AppBar position="static">
    <Container maxWidth={false}>
      <Toolbar disableGutters>

        <Box sx={{ flexGrow: 0, padding: "10px", borderRadius: "25px" }}>
          <img src={logo} alt='logo' />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' }, flexFlow: 'wrap' }}>
            {pages.map((page) => (
              <LinkCustom 
                key={page}
                // onTouchEnd={() => navigate("" + page)}
                // onClick={() => navigate("" + page)}
                to= {page}
              
                //
              >
                <Button  sx={{ my: 2, color: 'white', textDecoration:'none', display: 'block', fontWeight: location.pathname.endsWith("/" + page) ? 'bold' : '400' }}>
                {page}
                </Button>
              
              </LinkCustom >
            ))}
          </Box>
        </Box>
      </Toolbar>
    </Container>
  </AppBar>;

};

export default AppBar_Cabinet;

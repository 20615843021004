import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAppState, ILoginState, ECLoginState, AdminFullTransData } from './interfaces';
import { AppThunk } from '..';
import Cookies from 'js-cookie';

const initialState: IAppState = {
  loginToken: undefined,
  email: undefined,
  loginState: ECLoginState.FirstStart
};

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    systemLogin(state, action: PayloadAction<ILoginState>) {
      state.loginState = ECLoginState.LoggedIn;
      console.log(action.payload);
      if (state.loginToken != action.payload.loginToken || state.email != action.payload.email) {
        state.loginToken = action.payload.loginToken;
        state.email = action.payload.email;
        Cookies.set('InstaVpn_Admin_TokenKey', state.loginToken, { expires: 360, path: "/" })
        Cookies.set('InstaVpn_Admin_Email',state.email, { expires: 360, path: "/" })
      }
    },
    setStorageToken(state, action: PayloadAction<ILoginState>) {
      state.loginToken = action.payload.loginToken;
      state.email = action.payload.email;
    },
    systemNotLogged(state) {
      state.loginState = ECLoginState.SignOut;
      state.loginToken = undefined;
      state.email = undefined;
      //Cookies.remove('InstaVpn_Admin_TokenKey')
    }
  },
});


export const { systemLogin, setStorageToken,
  systemNotLogged } =
  authSlice.actions;




  export const loginCheck =
  (): AppThunk =>
    async (dispatch) => {
      try {
        const tokenValue = Cookies.get('InstaVpn_Admin_TokenKey');
        const emailValue = Cookies.get('InstaVpn_Admin_Email');
        if ((!tokenValue || tokenValue==""))
        {
          dispatch(systemNotLogged());
          return;
        }
        var response: any = await fetch(' https://1d8nfr00e4.execute-api.eu-west-2.amazonaws.com/master/Admin/AdminCheckLogin', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'admin_insta_jwttoken': tokenValue ??""
          },
          body: JSON.stringify({
          }),
        })
        var responseData: {responceCode: number } = await response.json();
        console.log(responseData);
        if (responseData.responceCode >= 0) {
          dispatch(systemLogin({ loginToken: tokenValue!,email:emailValue??"" }))
        }
        else{
          dispatch(systemNotLogged());
        }

      } catch (e) {
        // error reading value
        dispatch(systemNotLogged());
      }
    }

export default authSlice.reducer;

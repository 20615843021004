
import React, { Fragment, useEffect, useState } from "react";
import { } from "@store/auth";
import { addDays, format as dateFormat } from "date-fns";
import { subWeeks, isAfter, subMonths, parse as dateParse } from "date-fns";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear,parseISO } from 'date-fns';
import { DataGrid, GridColDef, GridColumnGroupingModel, GridValueGetterParams } from '@mui/x-data-grid';
import { GridData, FilteredGridData } from "./interfaces";
import { Paper, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { customFormatInTimeZone, dateCombinedComparator } from "@src/helpers";
import styled from '@emotion/styled';
import { DataGridCustom } from "./style";

type Props = {
  columnsGrouping?: GridColumnGroupingModel | undefined,
  columns: GridColDef[],
  data: GridData[]
};

const CustomStatDataGrid = (props: Props) => {
  const [filteredData, setFilteredData] = useState<FilteredGridData[]>([]);
  const [filteredColumnData, setFilteredColumnData] = useState<GridColDef[]>([]);
  const [filterType, setFilterType] = useState<string>("Day");
  useEffect(() => {
    const filterData = () => {
      const copy =[...props.data]; 
      const tempList: FilteredGridData[] = copy.reduce((acc: FilteredGridData[], item: GridData) => {
        let startDate: Date;
        let endDate: Date;
        const copyItem:GridData = {...item};
        const date = parseISO(copyItem.Date);
        switch (filterType) {
          case 'Week': {
            startDate = addDays(startOfWeek(date,{weekStartsOn: 4}),1);
            endDate = endOfWeek(date, {weekStartsOn: 4});
            break;
          }
          case 'Month': {
            startDate = startOfMonth(date);
            endDate = endOfMonth(date);
            break;
          }
          case 'Year': {
            startDate = startOfYear(date);
            endDate = endOfYear(date);
            break;
          }
          default:
            return acc;
        }

        if (acc.some(t => t.DateEnd.getTime() === endDate.getTime() && t.DateStart.getTime() === startDate.getTime())) {
          let dataPoint = acc.find(t => t.DateEnd.getTime() === endDate.getTime() && t.DateStart.getTime() === startDate.getTime());
          for (const key in dataPoint) {
            if (key!=="Date" && (typeof dataPoint[key] === 'string' || typeof dataPoint[key]==='number')) {
              dataPoint[key] = Number(dataPoint[key]) + Number(copyItem[key]);
            }
          }
        }
        else {
          let newElem: FilteredGridData = copyItem as FilteredGridData;
          newElem.DateStart = startDate;
          newElem.DateEnd = endDate;
          acc.push(newElem);
        }

        return acc;
      }, [])
      console.log(tempList);
      setFilteredData(tempList)
      const copyCol =[...props.columns]; 
      setFilteredColumnData(copyCol.map((item) => {
        const copyItem:GridColDef = {...item};
        if (copyItem.field ==="Date" || (copyItem.field ==="DateStart"))
        {
          copyItem.headerName=filterType;
          copyItem.sortComparator = dateCombinedComparator;
          copyItem.valueGetter = (params: GridValueGetterParams) =>
          `${customFormatInTimeZone(params.row.DateStart.toISOString())} - ${customFormatInTimeZone(params.row.DateEnd.toISOString())}`
        }
        return copyItem;
      }))
    }
    if (filterType !== "Day") {
      filterData();
    }
  }, [props.data, props.columns, filterType])


  return <Fragment>
    <Paper style={{ marginBottom: "10px", marginTop: "10px", padding: "10px" }}>
      <FormControl>
        <Select
          labelId="filter-select-label"
          id="filter-select"
          value={filterType}
          onChange={(event) => {
            setFilterType(event.target.value)
          }}>
          <MenuItem value="Day">Dayly</MenuItem>
          <MenuItem value="Week">Weekly</MenuItem>
          <MenuItem value="Month">Monthly</MenuItem>
          <MenuItem value="Year">Yearly</MenuItem>
        </Select>
      </FormControl>
    </Paper>
    <DataGridCustom
      
      density={'compact'}
      style={{marginBottom: "20px"}}
      rowSelection={false}
      experimentalFeatures={{ columnGrouping: props.columnsGrouping !== undefined }}
      rows={filterType !== "Day" ? filteredData : props.data}
      columnGroupingModel={props.columnsGrouping}
      rowHeight={70}
      columns={filterType !== "Day" ? filteredColumnData : props.columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        }
      }}
      pageSizeOptions={[5, 20, 50, 100, 1000]}
    />
  </Fragment>;
};

export default CustomStatDataGrid;

import React from "react";
import AuthLayout from "@components/Layouts/Auth";
import CabinetLayout from "@components/Layouts/Cabinet";
import SignIn from "@pages/AuthPages/SignIn";
import Page404 from "@pages/Page404";
import Dashboard from "@pages/ProfilePages/Dashboard";
import CurrentTrans from "@pages/ProfilePages/CurrentTrans";
import FutureTrans from "@pages/ProfilePages/FutureTrans";
import UsersList from "@pages/ProfilePages/Users";
import FreeUserList from "@pages/ProfilePages/FreeUsers";
import TestUserList from "@pages/ProfilePages/TestUsers";
import HistoricalSubs from "@pages/ProfilePages/HistoricalSubs";
import CanceledTrans from "@pages/ProfilePages/CanceledTrans";
import AdminTools from "@pages/ProfilePages/AdminTools";
import UserActivity from "@pages/ProfilePages/UserActivity";
import UserBehaviour from "@pages/ProfilePages/UserBehaviour";

export const mainRoutes = {
    path: 'Profile',
    element: <CabinetLayout />,
    children: [
      {path: '', element: <Dashboard />},
      {path: 'Dasboard', element: <Dashboard />},
      {path: 'CurrentTrans', element: <CurrentTrans />},
      {path: 'FutureTrans', element: <FutureTrans />},
      {path: 'TestUsers', element: <TestUserList />},
      {path: 'FreeUsers', element: <FreeUserList />},
      {path: 'CanceledTrans', element: <CanceledTrans />},
      {path: 'HistoricalSubs', element: <HistoricalSubs />},  
      {path: 'Users', element: <UsersList />},
      {path: 'UserBehaviour', element: <UserBehaviour />},   
      {path: 'AdminTools', element: <AdminTools />},
      {path: 'AdminTools/tools/:userId', element: <UserActivity />},
      {path: '*', element: <Page404 />}
    ],
  };

 export const accountRoutes = {
    path: '/',
    element: <AuthLayout />,
    children: [
      {path: '', element: <SignIn />},
      {path: '*', element: <SignIn />}
    ],
  };


import { CssBaseline, Container } from "@mui/material";
// import i18n from "@services/i18n/i18n";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {  Outlet, useNavigate} from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../store";
import { ECLoginState } from "../../../store/auth/interfaces";
import { loginCheck } from "../../../store/auth";
type Props = {
  children?: React.ReactNode;
};
const AuthLayout = ({ children }: Props) => {

  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  useEffect(() => {

    if (authState.loginState===ECLoginState.LoggedIn)
    {
      navigate('/profile'); 
    } else if (authState.loginState===ECLoginState.FirstStart)
    {
      //TODO: check Token
      //dispatch((getBackendState()));
      dispatch((loginCheck()));
    }

  },[authState.loginState,navigate,dispatch])

 

  return <>
    <CssBaseline />
    <Helmet>
      <title>InstaVPN | Авторизация</title>
      <meta property="title" content={"InstaVPN | Авторизация"} />
      <meta property="description" content={`Войдите в свою учетную запись InstaVPN с уверенностью, зная, 
      что наш безопасный процесс авторизации обеспечивает защиту ваших чувствительных данных.`} />
      <meta property="og:title" content={"InstaVPN | Авторизация"} />
      <meta property="og:description" content={`Войдите в свою учетную запись InstaVPN с уверенностью, зная, 
      что наш безопасный процесс авторизации обеспечивает защиту ваших чувствительных данных.`} />
    </Helmet>
    <Container maxWidth={false}>
      <div>
        <Outlet />
      </div>
    </Container>
  </>;
  
};

export default AuthLayout;

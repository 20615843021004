
import React, { Fragment, useEffect, useState } from "react";
import { MainContainer } from "./style";
import { AdminUserActivity, ECLoginState, TestUser, UserBasicInfo } from "@store/auth/interfaces";
import { useAppSelector, useAppDispatch } from "@store/index";
import { } from "@store/auth";
import { useNavigate } from "react-router-dom";
import { generateGuestToken, getAdminTransStatistic, getAdminUserActivity, sendNewTestUsers } from "@src/ApiFunctions/statFunc";
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Autocomplete, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { customFormatInTimeZone, dateComparator } from "@src/helpers";

function isValidEmail(email: string) {
  // Email validation regular expression
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const AdminTools = () => {
  const siteState = useAppSelector((state) => state.auth);
  const [userData, setUserData] = useState<UserBasicInfo[]>([]);
  const [language, setLanguage] = useState('en');
  const [user, setUser] = useState('');
  const [userActivity, setUserActivity] = useState<UserBasicInfo|null>(null);
  const [userActivityList, setUserActivityList] = useState<AdminUserActivity[]|undefined>(undefined);
  const [linkUser, setLinkUser] = useState('');
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    if (siteState.loginState === ECLoginState.LoggedIn) {
      getAdminTransStatistic("UserBasicList", siteState.loginToken!).then(result => {
        if (result != null) {
          setUserData((result as UserBasicInfo[]));
        }

      })
    } else {
      navigate('/');
    }
  }, [navigate, siteState.loginState, siteState.loginToken])

  const generateLink = async () => {
    setLinkUser(user);
    const selectedUserInfo = userData.find(t => t.email === user)
    if (selectedUserInfo === undefined)
    {
      alert('Error!')
      return;
    }
    console.log(selectedUserInfo)
    const result = await generateGuestToken(selectedUserInfo.uuid, siteState.loginToken!);
    console.log(result)
    setToken(result);
  }

  useEffect(() => {
    const pullUserActivity = async () =>{
      console.log(userActivity, userActivity?.id.toString() ?? "")
      getAdminUserActivity(userActivity?.id.toString() ?? "", siteState.loginToken!).then(result => {
        console.log(result)
        if (result != null) {
          setUserActivityList((result as AdminUserActivity[]));
        }
  
      })
    }

    if (userActivity!=null) {
      pullUserActivity();
    } 
  }, [userActivity])
  
  
  const columns: GridColDef[] = [
    {
      field: 'date', headerName: 'Date', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.date)}`
    },
    { field: 'EventType', headerName: 'Event Type', flex: 1 },
    { field: 'EventInfo', headerName: 'Event Info',   flex: 1 },
  ];

  return <MainContainer>
    {!userData || userData.length == 0 ?
      <div>Loading</div>
      : <Fragment>
        <Paper style={{ marginBottom: "10px", padding: "10px" }}>
          <FormControl>
            <InputLabel id="language-select-label">Language</InputLabel>
            <Select
              labelId="language-select-label"
              id="language-select"
              value={language}
              onChange={(event) => {
                setLanguage(event.target.value)
              }}>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="ar">Arabic</MenuItem>
              <MenuItem value="ru">Russian</MenuItem>
            </Select>
          </FormControl>
          <Typography>Language change link -  <a href={"instavpn://language/" + language}>Link</a></Typography>
        </Paper>
        <Paper style={{ marginBottom: "10px", padding: "10px" }}>
          <Typography>Logout link -  <a href="instavpn://logout">Link</a></Typography>
        </Paper>
        <Paper style={{ marginBottom: "10px", padding: "10px" }}>
          <FormControl>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={userData.find((option) => option.uuid === user) || null}
              getOptionLabel={(option) => option.email  + ' (' + option.uuid + ')'}
              options={userData}
              sx={{ width: 300 }}
              onChange={(e, data) => setUser(data?.email ?? "")}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.email}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Email"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

          </FormControl>
          <Button onClick={generateLink}   onTouchEnd={generateLink} >Generate link </Button>
          {token && token.length > 0 && <Typography>Guest Access link for user {linkUser}-  <a href={"instavpn://token/" + token}>Link</a></Typography>}
        </Paper>
        <Paper style={{ marginBottom: "10px", padding: "10px" }}>
          <Typography>User Activity report</Typography>
          <FormControl>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={userData.find((option) => option === userActivity) || null}
              getOptionLabel={(option) => option.email  + ' (' + option.uuid + ')'}
              options={userData}
              sx={{ width: 300 }}
              onChange={(e, data) => 
                { 
                  console.log(data)
                  if (data!=null)
                  {
                    console.log('Not null')
                    setUserActivityList(undefined)
                    setUserActivity({email: data.email, id: data.id, phone: data.phone, uuid: data.uuid})
                  }
                  else
                  {
                    console.log('Null')
                    setUserActivity(null)
                  }
                }}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.email + '   (' + option.uuid + ')'}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Email  (Uuid)"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

          </FormControl>
          {/* <Button onClick={pullUserActivity} onTouchEnd={pullUserActivity} >Show activity</Button> */}
          {userActivityList===undefined && userActivity!=null &&     <Typography>Loading.. </Typography>}
          {userActivityList && <Fragment>
            <Typography>Activity List for {userActivity?.email} </Typography>
            <DataGrid
              density={'compact'}
              rowSelection={false}
              rows={userActivityList}
              rowHeight={70}
              columns={columns}
              getRowId={(row: AdminUserActivity) => { return row.date; }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 20 },
                },
              }}
              pageSizeOptions={[5, 10, 50, 100, 1000]}
            />
          </Fragment>}
        </Paper>
      </Fragment>}
  </MainContainer>;

};

export default AdminTools;

import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';

export const DataGridCustom = styled(DataGrid)`
&& .MuiDataGrid-row {
  & .MuiDataGrid-withBorderColor {
    border-color: rgba(224, 224, 224, 1)!important;
    border-right: 1px solid rgba(224, 224, 224, 1)!important;;
    border-left: 1px solid rgba(224, 224, 224, 1)!important;;
  }
}
`;


import React, { Fragment, useEffect, useState } from "react";
import { MainContainer } from "./style";
import { ECLoginState, TestUser } from "@store/auth/interfaces";
import { useAppSelector, useAppDispatch } from "@store/index";
import {  } from "@store/auth";
import { useNavigate } from "react-router-dom";
import { getAdminTransStatistic, sendNewTestUsers } from "@src/ApiFunctions/statFunc";
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

function isValidEmail(email:string) {
  // Email validation regular expression
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const TestUserList = () => {
  const siteState = useAppSelector((state) => state.auth);
  const [statData, setStatData] = useState<TestUser[]>([]);
  const [emailList, setEmailList] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (siteState.loginState === ECLoginState.LoggedIn) {
      getAdminTransStatistic("TestUsers", siteState.loginToken!).then(result => {
        if (result!=null)
        {
          setStatData(result as TestUser[]);
        }

      })
    } else
    {
        navigate('/');
    }
  }, [])


  const columns: GridColDef[] = [
    { field: 'id',  type: 'number', headerName: 'id' ,flex:0},
    { field: 'phone', headerName: 'Phone',flex:1 },
    { field: 'email', headerName: 'Email',flex:3 },
  ];

  const sendEmailList = async() => {
    if (emailList.length==0) return;
    let tempLine=emailList;
    let unparsedList= tempLine.replace(/\s/g, '').replace(',',';').split(';');
    let parsedList: string[] = [];
    unparsedList.forEach(email =>{
      if (isValidEmail(email))
      {
        parsedList.push(email);
      }
    })

    let result=await sendNewTestUsers(parsedList, siteState.loginToken!);
    setEmailList("");
    if (result === true)
    {
      getAdminTransStatistic("TestUsers", siteState.loginToken!).then(result => {
        if (result!=null)
        {
          setStatData(result as TestUser[]);
        }

      })
    }

  }

  return <MainContainer>
    {!statData || statData.length==0 ? 
    <div>Loading</div> 
      : <Fragment>
        <Paper style={{marginBottom:"10px",padding:"10px"}}>
          <Grid container direction={"column"} gap={"10px"}>
            <Typography>Добавление "нашего"/тестового юзера</Typography>
            <Grid container direction={"row"} gap={"10px"}>
              <TextField id="outlined-basic" label="Email" variant="outlined"
                value={emailList}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmailList(event.target.value);
                }} />
              <Button variant="contained" onClick={sendEmailList}>Добавить</Button>
            </Grid>
          </Grid>
        </Paper>
           <DataGrid
           density={'compact'}
           rowSelection={false}
           rows={statData}
           rowHeight={70}
           columns={columns}
           style={{maxWidth:"600px"}}
           getRowId={(row:TestUser)=>{return row.id;}}
           initialState={{
             pagination: {
               paginationModel: { page: 0, pageSize: 20 },
             },
             sorting:{
              sortModel:[{
                field:"id",
                sort:"desc"
              }]
             }
           }}
           pageSizeOptions={[5, 10,50,100,1000]}
         />
         </Fragment>}
  </MainContainer>;

};

export default TestUserList;


import React, { Fragment, useEffect, useState } from "react";
import { MainContainer } from "./style";
import {  AdminUserData, ECLoginState } from "@store/auth/interfaces";
import { useAppSelector, useAppDispatch } from "@store/index";
import {  } from "@store/auth";
import { useNavigate } from "react-router-dom";
import { getAdminTransStatistic } from "@src/ApiFunctions/statFunc";
import { DataGrid, GridColDef,GridComparatorFn, GridEventListener, GridEventLookup, GridValueGetterParams } from '@mui/x-data-grid';
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { dateComparator, customFormatInTimeZone } from "@src/helpers";


const UsersList = () => {
  const siteState = useAppSelector((state) => state.auth);
  const [statData, setStatData] = useState<AdminUserData[]>([]);
  const [filteredData, setFilteredData] = useState<AdminUserData[]>([]);
  const [showSelected, setShowSelected] = useState<string[]>(['paid']);
  const navigate = useNavigate();
  useEffect(() => {
    if (siteState.loginState === ECLoginState.LoggedIn) {
      getAdminTransStatistic("UserList", siteState.loginToken!).then(result => {
        if (result!=null)
        {
          setStatData(result as AdminUserData[]);
        }

      })
    } else
    {
        navigate('/');
    }
  }, [])

  useEffect(() => {
    const filterData = () => {
      setFilteredData(statData.filter(t=> showSelected.some(x=>x === t.status) ))
    }
    filterData();
  }, [showSelected, statData])



  const onRowClick = (data: any ) => {
    if (data.field !== undefined && data.field ==='id') {
      console.log('/AdminTools/tools/'+ data.value)
      window.open('/profile/AdminTools/tools/'+ data.value, "_blank");
    }
  }
  const columns: GridColDef[] = [
    { field: 'id',  type: 'number', headerName: 'id' ,flex:0},
    { field: 'status', headerName: 'Status',flex:1 },
    { field: 'email', headerName: 'Email',width: 50,flex:2, },
    { field: 'phone', headerName: 'Phone',flex:1 },
    { field: 'created', headerName: 'Created',flex:1,
    sortComparator:dateComparator,
    valueGetter: (params: GridValueGetterParams) =>
    `${customFormatInTimeZone(params.row.created)}` },
    { field: 'trialUsed', headerName: 'TrialUsed' ,flex:1},
    { field: 'currentPlan', headerName: 'Текущий Тариф' ,flex:1},
    { field: 'planStart', headerName: 'Старт Тарифа',flex:1,
    sortComparator:dateComparator,
    valueGetter: (params: GridValueGetterParams) =>
    `${params.row.planStart!=null ? customFormatInTimeZone(params.row.planStart):"N/A"}` },
    { field: 'language', headerName: 'Language',flex:0 },
    { field: 'user_country', headerName: 'Country',flex:0 },
    { field: 'promocode', headerName: 'PromoCode',flex:0 },
  ];

  return <MainContainer>
    {!statData || statData.length == 0 ?
      <div>Loading</div>
      : <Fragment>
        <FormGroup row={true} style={{ marginBottom: "10px" }}>
          <FormControlLabel control={<Checkbox
            checked={showSelected.some(x => x === 'test')}
            onChange={(event, checked) => {
              if (showSelected.some(x => x === 'test')) {
                setShowSelected(list => list.filter(x => x !== 'test'))
              } else {
                setShowSelected(list => [...list, 'test'])
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Тестовые/Свои" />
          <FormControlLabel control={<Checkbox
            checked={showSelected.some(x => x === 'paid')}
            onChange={(event, checked) => {
              if (showSelected.some(x => x === 'paid')) {
                setShowSelected(list => list.filter(x => x !== 'paid'))
              } else {
                setShowSelected(list => [...list, 'paid'])
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Платящие (пользователи с любой оплаченной нетриальной подпиской)" />
          <FormControlLabel control={<Checkbox
            checked={showSelected.some(x => x === 'trial')}
            onChange={(event, checked) => {
              if (showSelected.some(x => x === 'trial')) {
                setShowSelected(list => list.filter(x => x !== 'trial'))
              } else {
                setShowSelected(list => [...list, 'trial'])
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Триалы (запустил триал, триал не закончился)" />



          <FormControlLabel control={<Checkbox
            checked={showSelected.some(x => x === 'paid_finished')}
            onChange={(event, checked) => {
              if (showSelected.some(x => x === 'paid_finished')) {
                setShowSelected(list => list.filter(x => x !== 'paid_finished'))
              } else {
                setShowSelected(list => [...list, 'paid_finished'])
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Законченная покупка (покупал, подписка закончилась и не продлилась)" />
          <FormControlLabel control={<Checkbox
            checked={showSelected.some(x => x === 'trial_finished')}
            onChange={(event, checked) => {
              if (showSelected.some(x => x === 'trial_finished')) {
                setShowSelected(list => list.filter(x => x !== 'trial_finished'))
              } else {
                setShowSelected(list => [...list, 'trial_finished'])
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Законченый триал (запускал триал, не покупал, триал закончился)" />
          <FormControlLabel control={<Checkbox
            checked={showSelected.some(x => x === 'new')}
            onChange={(event, checked) => {
              console.log(checked)
              if (showSelected.some(x => x === 'new')) {
                setShowSelected(list => list.filter(x => x !== 'new'))
              } else {
                setShowSelected(list => [...list, 'new'])
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Новые (Остальные)" />
        </FormGroup>
        <DataGrid
          density={'compact'}
          rowSelection={false}
          onCellClick={onRowClick}
          rows={filteredData}
           rowHeight={70}
           columns={columns}
           getRowId={(row:AdminUserData)=>{return row.id;}}
           initialState={{
             pagination: {
               paginationModel: { page: 0, pageSize: 20 },
             },
             sorting:{
              sortModel:[{
                field:"created",
                sort:"desc"
              }]
             }
           }}
           pageSizeOptions={[5, 10,50,100,1000]}
         />
         </Fragment>}
  </MainContainer>;

};

export default UsersList;

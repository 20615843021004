
import React, { Fragment, useEffect, useState } from "react";
import { MainContainer } from "./style";
import { AdminCancelTransData, AdminFullTransData, ECLoginState, ESPlanId, EShopId } from "@store/auth/interfaces";
import { useAppSelector, useAppDispatch } from "@store/index";
import { } from "@store/auth";
import { getAdminTransStatistic } from "@src/ApiFunctions/statFunc";
import { useNavigate } from "react-router-dom";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { formatDuration } from 'date-fns';
import { dateComparator, customFormatInTimeZone } from "@src/helpers";
import { DataGrid, GridColDef, GridComparatorFn, GridValueGetterParams } from '@mui/x-data-grid';


const CanceledTrans = () => {
  const siteState = useAppSelector((state) => state.auth);
  const [statData, setStatData] = useState<AdminCancelTransData[]>([]);
  const [filteredData, setFilteredData] = useState<AdminCancelTransData[]>([]);
  const [showTest, setShowTest] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (siteState.loginState === ECLoginState.LoggedIn) {
      getAdminTransStatistic("SubscriptionCancelStat", siteState.loginToken!).then(result => {
        console.log(result)
        if (result != null) {
          setStatData(result as AdminCancelTransData[]);
        }

      })
    } else {
      navigate('/');
    }
  }, [])


  useEffect(() => {
    const filterData = () => {
      setFilteredData(statData.filter(t =>
        t.isTest === showTest))
    }
    filterData();
  }, [showTest,statData])


  const columns: GridColDef[] = [
    {
      field: 'date', headerName: 'Date', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.date)}`
    },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'canceledOn', headerName: 'Plan Name',   flex: 1 },
    { field: 'canceledRec', headerName: 'Plan name for recurring', flex: 1 },
    { field: 'reason', headerName: 'Reason', flex: 1 },
    { field: 'timeFromStart', headerName: 'Time from start', flex: 2 , sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${formatDuration(params.row.timeFromStart)}`},
  ];

  return <MainContainer>
    {!statData || statData.length == 0 ?
      <div>Loading</div>
      : <Fragment>
        <Paper>
          <FormGroup row={true} style={{ marginBottom: "10px" }}>
            <FormControlLabel control={<Checkbox
              checked={showTest}
              onChange={(event, checked) => setShowTest(checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label="Тестовые" />
          </FormGroup>
        </Paper>
        <DataGrid
          density={'compact'}
          rowSelection={false}
          rows={filteredData}
          rowHeight={70}
          columns={columns}
          getRowId={(row: AdminCancelTransData) => { return row.date; }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100, 1000]}
        />
      </Fragment>}
  </MainContainer>;

};

export default CanceledTrans;

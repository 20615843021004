
import React, { Fragment, useEffect, useState } from "react";
import { MainContainer } from "./style";
import { AdminGeneralDayStat, AdminStatisticActiveSubscriptions, ECLoginState, DasboardStatistic, AdminTrialDaylyData } from "@store/auth/interfaces";
import { useAppSelector, useAppDispatch } from "@store/index";
import { } from "@store/auth";
import { useNavigate } from "react-router-dom";
import { getAdminTransStatistic, getDasboardStatistic } from "@src/ApiFunctions/statFunc";
import { format as dateFormat, parseISO } from "date-fns";
import { subWeeks, isAfter, subMonths, parse as dateParse } from "date-fns";
import { DataGrid, GridColDef, GridColumnGroupingModel, GridComparatorFn, GridValueGetterParams } from '@mui/x-data-grid';
import { FormGroup, FormControlLabel, Checkbox, Typography, Grid, Select, FormControl, MenuItem } from '@mui/material';
import { ResponsiveContainer, CartesianGrid, LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import CustomStatDataGrid from "@components/Custom/CustomStatDataGrid";
import { GridData } from "@components/Custom/CustomStatDataGrid/interfaces";
import { dateComparator, customFormatInTimeZone } from "@src/helpers";


const Dashboard = () => {
  const siteState = useAppSelector((state) => state.auth);
  const [statData, setStatData] = useState<AdminGeneralDayStat[]>([]);
  const [statTrialData, setStatTrialData] = useState<AdminTrialDaylyData[]>([]);
  const [filterTrialData, setFilterTrialData] = useState<AdminTrialDaylyData[]>([]);
  const [filteredData, setFilteredData] = useState<AdminGeneralDayStat[]>([]);
  const [subsData, setSubsData] = useState<AdminStatisticActiveSubscriptions[]>([]);
  const [showTest, setShowTest] = useState<boolean>(false);
  const [languageFilter, setLanguageFilter] = useState<string|null>(null);
  const [dopLanguages, setDopLanguages] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (siteState.loginState === ECLoginState.LoggedIn) {
      getDasboardStatistic(siteState.loginToken!).then(result => {
        if (result != null) {
          setStatData(result.generalData);
          setSubsData(result.general_activeData)
        }
      })
      getAdminTransStatistic("TrialyDayStat", siteState.loginToken!).then(result => {
        console.log(result)
        if (result != null) {
          setStatTrialData(result as AdminTrialDaylyData[]);
        }

      })
    } else {
      navigate('/');
    }
  }, [])

  useEffect(() => {
    const filterData = () => {
      setFilteredData(statData.filter(t =>
        t.is_test === showTest))
    }
    filterData();

  }, [showTest, statData])
  useEffect(() => {
    const upFilterTrialData = () => {
      setFilterTrialData(statTrialData.filter(t =>
        ( t.is_test === showTest) && t.language === languageFilter))
    }
    function onlyUnique(value: any, index: any, array: string | any[]) {
      return array.indexOf(value) === index;
    }
    const updateLanguageList = () => {
      let dopList: string[] = statTrialData.map(t=>t.language ||"ru").filter(onlyUnique)
      .filter(t=> t !== 'en' && t !== 'ru' && t !== 'ar' && t !== null);
      setDopLanguages(dopList);
    }
    upFilterTrialData();
    updateLanguageList();
  }, [showTest, languageFilter, statTrialData])



  const columns: GridColDef[] = [
    {
      field: 'Date', headerName: 'Day', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.Date)}`
    },
    { field: 'IosCount', type: 'number', headerName: 'Ios Connection count', flex: 1 },
    { field: 'AndroidCount', type: 'number', headerName: 'Android Connection count', flex: 1 },
    { field: 'UserCount', type: 'number', headerName: 'Registrations', flex: 1 },
    { field: 'Login', type: 'number', headerName: 'Successfull login', flex: 1 },
    { field: 'UnfinLogin', type: 'number', headerName: 'Unsuccessfull login', flex: 1 },
    {
      field: 'TransactionCount', type: 'number', headerName: 'Total Transaction Count', flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.TransactionCount
    },
    { field: 'WeekCount', type: 'number', headerName: 'Weekly Count', flex: 1 },
    { field: 'MonthCount', type: 'number', headerName: 'Monthly Count', flex: 1 },
    { field: 'HalfYearCount', type: 'number', headerName: 'HalfYear Count', flex: 1 },
    { field: 'YearCount', type: 'number', headerName: 'Yearly Count', flex: 1 },
    { field: 'TrialCount', type: 'number', headerName: 'Trialy Count', flex: 1 },
  ];

  const columnsTrialGrouping: GridColumnGroupingModel =
    [
      {
        groupId: 'Trial',
        children: [{ field: 'TrialCount' }, { field: 'TrialCountCV' }],
      },
      {
        groupId: 'Potential payments',
        children: [{ field: 'ReccuringCount' }, { field: 'ReccuringCountCVReg' }, { field: 'ReccuringCountCVTrial' }],
      },
      {
        groupId: 'Started paying',
        children: [{
          groupId: 'Immediate pay',
          children: [{ field: 'ImmediateCount' }, { field: 'ImmediateCountCVReg' }, { field: 'ImmediateCountCVTrial' }],
        },
        {
          groupId: 'Recurring',
          children: [{ field: 'ReccuredCount' }, { field: 'ReccuredCountCVReg' }, { field: 'ReccuredCountCVTrial' }],
        },
        {
          groupId: 'After trial',
          children: [{ field: 'ReccuredWithReccuredTrialCount' }, { field: 'AfterTrialCountCVReg' }, { field: 'AfterTrialCountCVTrial' }],
        },],
      },
    ]

  const columnsTrial: GridColDef[] = [
    {
      field: 'Date', headerName: 'Day', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.Date)}`
    },
    { field: 'RegisterCount', type: 'number', headerName: 'New install', flex: 1 },
    { field: 'TrialCount', type: 'number', headerName: 'Count', flex: 1 },
    {
      field: 'TrialCountCV', headerName: 'CV - Inst', flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.RegisterCount>0 ?  (params.row.TrialCount * 100 / params.row.RegisterCount).toFixed(2) : 0}%`
    },
    { field: 'ReccuringCount', type: 'number', headerName: 'Count', flex: 1 },
    {
      field: 'ReccuringCountCVReg', headerName: 'CV1 - Inst', flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.RegisterCount>0 ?  (params.row.ReccuringCount * 100 / params.row.RegisterCount).toFixed(2) : 0}%`
    },
    {
      field: 'ReccuringCountCVTrial', headerName: 'CV2 - Trial', flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.TrialCount>0 ? (params.row.ReccuringCount * 100 / params.row.TrialCount).toFixed(2) : 0}%`
    },
    {
      field: 'ImmediateCount',headerName: 'Count', flex: 1,  type: 'number', 
      valueGetter: (params: GridValueGetterParams) =>
        `${(params.row.ReccuredCount - params.row.ReccuredWithTrialCount )}`
    },
    {
      field: 'ImmediateCountCVReg', headerName: 'CV1 - Inst', flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.RegisterCount>0 
          ?  ((params.row.ReccuredCount - params.row.ReccuredWithTrialCount) 
          * 100 / params.row.RegisterCount).toFixed(2) 
          : 0}%`
    },
    {
      field: 'ReccuredCount',headerName: 'Count', flex: 1,  type: 'number', 
      valueGetter: (params: GridValueGetterParams) =>
        `${(params.row.ReccuredWithTrialCount - params.row.ReccuredWithReccuredTrialCount )}`
    },
    {
      field: 'ReccuredCountCVReg', headerName: 'CV1 - Inst', flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.RegisterCount>0 
          ?  ((params.row.ReccuredWithTrialCount - params.row.ReccuredWithReccuredTrialCount ) 
          * 100 / params.row.RegisterCount).toFixed(2) 
          : 0}%`
    },
    {
      field: 'ReccuredCountCVTrial', headerName: 'CV2 - Trial', flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.TrialCount>0 
          ? ((params.row.ReccuredWithTrialCount - params.row.ReccuredWithReccuredTrialCount ) 
          * 100 / params.row.TrialCount).toFixed(2) 
          : 0}%`
    },


    { field: 'ReccuredWithReccuredTrialCount', type: 'number', headerName: 'Count', flex: 1 },
    {
      field: 'AfterTrialCountCVReg', headerName: 'CV1 - Inst', flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.RegisterCount>0 ?  (params.row.ReccuredWithReccuredTrialCount * 100 / params.row.RegisterCount).toFixed(2) : 0}%`
    },
    {
      field: 'AfterTrialCountCVTrial', headerName: 'CV2 - Trial', flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.TrialCount>0 ? (params.row.ReccuredWithReccuredTrialCount * 100 / params.row.TrialCount).toFixed(2) : 0}%`
    },
    {
      field: 'CanceledCount', headerName: 'Canceled payment', flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.TrialCount - params.row.ReccuringCount - params.row.ReccuredWithTrialCount}`
    },
  ];

  const currentDate = new Date();
  const sevenDaysBefore = subWeeks(currentDate, 1);
  const thirtyDaysBefore = subMonths(currentDate, 1);
  const sevenDayTrialFilterLit = filterTrialData.filter(t => isAfter(parseISO(t.Date), sevenDaysBefore));
  const monthTrialFilterLit = filterTrialData.filter(t => isAfter(parseISO(t.Date), thirtyDaysBefore));
  console.log(sevenDayTrialFilterLit, monthTrialFilterLit)
  return <MainContainer>
    {!statData || statData.length == 0 ?
      <div>Loading</div>
      : <Fragment>
        <FormGroup row={true} style={{ marginBottom: "10px" }}>
          <FormControlLabel control={<Checkbox
            checked={showTest}
            onChange={(event, checked) => setShowTest(checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Тестовый вариант таблицы" />
        </FormGroup>
        {subsData.map((item, index) => {
          if (item.is_test == showTest) {
            return <Grid key={index} container direction="column" gap={"5px"}>
              <Typography variant="h5">Текущее положение активных подписок</Typography>
              <Typography>Общее число  - {item.count}</Typography>
              <Typography>Количество с включенной рекурентностью - {item.isReccuringAll} </Typography>
              <Typography>Количество триалов - {item.isTrial} </Typography>
              <Typography>Количество триалов с включенной рекурентностью - {item.isReccuringTrial} </Typography>
            </Grid>
          }
          else {
            return <Fragment key={index}></Fragment>
          }
        })}
        <Grid container direction="column" gap={"5px"}>
          <Typography variant="h5">Статистика по триалам</Typography>
          <Typography>За последние 7 дней: Всех триалов - {sevenDayTrialFilterLit.reduce((sum, t) => Number(sum) + Number(t.TrialCount), 0)}, 
          при этом продлено было - {sevenDayTrialFilterLit.reduce((sum, t) => Number(sum) + Number(t.ReccuredCount), 0)}</Typography>
          <Typography>За последние 7 дней: Еще не отменены и не продолжены - {sevenDayTrialFilterLit.reduce((sum, t) => Number(sum) + Number(t.ReccuringCount), 0)}</Typography>
          <Typography>За последние 30 дней : Всех триалов - {monthTrialFilterLit.reduce((sum, t) => Number(sum) + Number(t.TrialCount), 0)}, при этом продлено было - 
           {monthTrialFilterLit.reduce((sum, t) => Number(sum) + Number(t.ReccuredCount), 0)}</Typography>
          <Typography>За последние 30 дней: Отменено триалов - {monthTrialFilterLit.reduce((sum, t) => Number(sum) + Number(t.TrialCount) - Number(t.ReccuredCount) - Number(t.ReccuringCount), 0)}</Typography>
        </Grid>
        <Typography variant="h3">Trial Dynamics</Typography>
        <FormControl>
          <Typography variant="h6">Language filter</Typography>
          <Select
            labelId="filter-language-select-label"
            id="filter-language-select"
            value={languageFilter || "null"}
            onChange={(event) => {
              if (event.target.value==="null")
              {
                setLanguageFilter(null)
              }
              else
              {
                setLanguageFilter(event.target.value)
              }
            }}>
            <MenuItem value="null">All</MenuItem>
            <MenuItem value="ru">Russian</MenuItem>
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="ar">Arabic</MenuItem>
            {dopLanguages.map((item, index)=>  {
              return <MenuItem key={index} value={item}>{item}</MenuItem>
            })}
          </Select>
        </FormControl>
        <CustomStatDataGrid
          data={filterTrialData as GridData[]}
          columnsGrouping={columnsTrialGrouping}
          columns={columnsTrial}
        />

        <CustomStatDataGrid
          data={filteredData as GridData[]}
          columns={columns}
        />
        <Typography style={{ marginBottom: "10px", marginTop: "10px", fontSize: "20px", fontWeight: "bold" }}>Граф покупок</Typography>
        <LineChart
          width={800}
          height={400}
          data={[...filteredData].reverse()}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <XAxis dataKey="Date" tickFormatter={(label) => { return label.replace(".000Z", "").replace("T00:00:00", "") }} />
          <YAxis type="number"  dataKey={(v)=>parseInt(v.TransactionCount)}  domain={[0,'dataMax+5']}  scale="pow" />
          <Tooltip />
          <CartesianGrid stroke="#f5f5f5" />
          <Line type="monotone" dataKey="TransactionCount" dot={false} stroke="#000000" yAxisId={0} />
          <Line type="monotone" dataKey="WeekCount" dot={false} stroke="#ADD8E6" yAxisId={0} />
          <Line type="monotone" dataKey="MonthCount" dot={false} stroke="#00FF00" yAxisId={0} />
          <Line type="monotone" dataKey="HalfYearCount" dot={false} stroke="#ADD8E6" yAxisId={0} />
          <Line type="monotone" dataKey="YearCount" dot={false} stroke="#FFD700" yAxisId={0} />
          <Line type="monotone" dataKey="TrialCount" dot={false} stroke="#D3D3D3" yAxisId={0} />
        </LineChart>

      </Fragment>
    }
  </MainContainer >;
};

export default Dashboard;

export interface IAppState {
  loginState: ECLoginState;
  loginToken: string | undefined;
  email: string | undefined;
}

export interface UserAttributes {
  sub: string;
  email: string;
  email_verified: string;
  name: string;
  updated_at: string;
  'custom:userId': string;
}


export interface AdminFullTransData {
  TransId: number;
  userId: string;
  planName: string;
  DateTimeBuy: Date;
  DatimeReg: Date;
  Price: number;
  paymentSystem: string;
  statusId: number;
  Currency: number;
  subscriptionEnd: Date;
  environment: string;
  is_cancelled: boolean;
  orderId: string;
  vpnusername: string;
  phone: string;
  email: string;
  PrevPlan: string;
  promocode: string;
  TimeToBuy: Duration;
  IsFirst:boolean;
  IsRepeat:boolean;
  language:string;
  is_test:boolean;
}

export interface AdminTrialDaylyData {
  id: number;
  Date: string;
  RegisterCount: number;
  TrialCount: number;
  ReccuringCount: number;
  ReccuredCount: number;
  is_test:boolean|null;
  language: string|null;
}

export interface AdminCancelTransData {
  id: number;
  email: string;
  canceledOn: string;
  canceledRec: string;
  reason: string;
  date: string;
  timeFromStart: string;
  isTest:boolean;
}

export interface AdminHistoricalTransData {
  id: number;
  Date: string;
  Monthly: number;
  HalfYear: number;
  Year: number;
  Trial: number;
  CHFSum:number;
  CHFMonth:number;
  is_test:boolean;
}

export interface AdminFutureFullTransData {
  TransId: number;
  dateStart: Date;
  dateEnd: Date;
  userId: string;
  convertedSum:number;
  monthCount: string;
  CurrentPlan: string;
  NextPlan: string;
  promocode: string;
  vpnusername: string;
  phone: string;
  email: string;
  paymentSystem: string;
  isTest:boolean;
}

export interface AdminUserData {
  id: number;
  phone: string;
  status: string;
  email: string;
  created: Date;
  trialUsed: boolean;
  currentPlan: string;
  planStart: Date|undefined;
  language: string;
  user_country: string;
  promocode: string;
  isTest:boolean;
}

export interface AdminUserBehaviour {
  id: number;
  phone: string;
  email: string;
  created: Date;
  trialStart: Date | undefined; 
  planStart: Date | undefined; 
  planEnd: Date | undefined; 
  plan_len: number ; // Plan len in months
  success_count: number; // Success connection count
  all_count: number; // All connection count
  isTest: boolean;
}

export interface DasboardStatistic {
  generalData: AdminGeneralDayStat[];
  general_activeData: AdminStatisticActiveSubscriptions[];
}


export interface AdminGeneralDayStat {
  id:number;
  Date: string;
  IosCount: number;
  AndroidCount: number;
  UserCount: number;
  Login: number;
  UnfinLogin: number;
  TransactionCount: number;
  TrialCount: number;
  MonthCount: number;
  HalfYearCount: number;
  YearCount: number;
  is_test: boolean;
}

export interface AdminStatisticActiveSubscriptions {
  count: number;
  isReccuringAll: number;
  isReccuringTrial: number;
  isTrial: number;
  is_test: boolean;
}

export interface AdminUserActivity{
  userId:number;
  date: string;
  EventType: string;
  EventInfo:string;
}

export interface TestUser{
  id: number;
  phone: string;
  email: string;
}

export interface UserBasicInfo{
  id: number;
  phone: string;
  email: string;
  uuid: string;
}

export interface AdminFreeUser{
  id: number;
  phone: string;
  email: string;
  planName: string;
  created:Date;
  planEnd:Date;
  active: boolean;
}

export interface ILoginState {
  loginToken: string,
  email: string
}


export enum ECLoginState {
  FirstStart = 0,
  LoggedIn = 1,
  SignOut = 2
}

export enum EShopId {
  Apple = 1,
  Android = 2,
  Card = 3,
  RoboKassa=4
}

export enum ESPlanId {
  Month=1,
  MonthPromo=2,
  HalfYear=3,
  HalfYearPromo=4,
  Year=5,
  YearPromo=6,
  NonPremium=7,
  Trial=8
}



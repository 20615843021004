
import React, { Fragment, useEffect, useState } from "react";
import { MainContainer } from "./style";
import { AdminFreeUser, ECLoginState } from "@store/auth/interfaces";
import { useAppSelector, useAppDispatch } from "@store/index";
import { } from "@store/auth";
import { useNavigate } from "react-router-dom";
import { addFreeSubscription, getAdminTransStatistic } from "@src/ApiFunctions/statFunc";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { dateComparator, customFormatInTimeZone } from "@src/helpers";
import { DataGrid, GridColDef, GridComparatorFn, GridValueGetterParams } from '@mui/x-data-grid';
import { Grid, Typography, TextField, Button, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

function isValidEmail(email: string) {
  // Email validation regular expression
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const planList = [
  { id: 1, planName: "Month" },
  { id: 3, planName: "HalfYear" },
  { id: 4, planName: "Week" },
  { id: 5, planName: "Year" },
]

const FreeUserList = () => {
  const siteState = useAppSelector((state) => state.auth);
  const [statData, setStatData] = useState<AdminFreeUser[]>([]);
  const [filteredData, setFilteredData] = useState<AdminFreeUser[]>([]);
  const [free_email, setFree_email] = useState<string>("");
  const [free_planId, setFree_planId] = useState<number>(1);
  const [free_modal_open, setFree_modal_open] = useState<boolean>(false);
  const [showActive, setShowActive] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (siteState.loginState === ECLoginState.LoggedIn) {
      getAdminTransStatistic("FreeUsers", siteState.loginToken!).then(result => {
        if (result != null) {
          setStatData(result as AdminFreeUser[]);
        }

      })
    } else {
      navigate('/');
    }
  }, [])


  useEffect(() => {
    filterData();
  }, [showActive, statData])
  const filterData = () => {
    setFilteredData(statData.filter(t =>
      t.active == showActive))
  }


  const sendEmailList = async () => {
    setFree_modal_open(false)
    if (free_email.length == 0 || !isValidEmail(free_email)) return;


    let result = await addFreeSubscription(free_email, free_planId, siteState.loginToken!);
    setFree_email("");
    if (result == true) {
      getAdminTransStatistic("FreeUsers", siteState.loginToken!).then(result => {
        if (result != null) {
          setStatData(result as AdminFreeUser[]);
        }
      })
    }

  }


  const columns: GridColDef[] = [
    { field: 'id', type: 'number', headerName: '№', flex: 0 },
    { field: 'email', headerName: 'Email', flex: 2 },
    { field: 'planName', headerName: 'Current Plan', flex: 1 },
    {
      field: 'created', headerName: 'Created', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.created)}`
    },
    {
      field: 'planEnd', headerName: 'Plan End', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.planEnd)}`
    }
  ];

  return <MainContainer>
    {/* {!statData || statData.length==0 ?  */}
    {false ?
      <div>Loading</div>
      : <Fragment>

        <Dialog
          open={free_modal_open}
          onClose={()=>{setFree_modal_open(false)}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Создание бесплатной подписки"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            Вы уверены? Перепроверьте: {free_email} - {planList.find(t=>t.id==free_planId)?.planName}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{setFree_modal_open(false)}}>Отменить</Button>
            <Button onClick={sendEmailList} autoFocus>
              Да, все правильно
            </Button>
          </DialogActions>
        </Dialog>

        <Paper style={{ marginBottom: "10px", padding: "10px" }}>
          <Grid container direction={"column"} gap={"10px"}>
            <Typography>Добавление бесплатной подписки</Typography>
            <Grid container direction={"row"} gap={"10px"}>
              <TextField id="outlined-basic" label="Email" variant="outlined"
                value={free_email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFree_email(event.target.value);
                }} />
              <TextField
                id="outlined-select-planId"
                select
                label="Select"
                value={free_planId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFree_planId(Number(event.target.value));
                }}
                helperText="Please select plan"
              >
                {planList.map((plan) => (
                  <MenuItem key={plan.id} value={plan.id}>
                    {plan.planName}
                  </MenuItem>
                ))}
              </TextField>
              <Button variant="contained" onClick={()=>{setFree_modal_open(true)}}>Добавить</Button>
            </Grid>
          </Grid>
        </Paper>

        <FormGroup style={{ marginBottom: "10px" }}>
          <FormControlLabel control={<Checkbox
            checked={showActive}
            onChange={(event, checked) => setShowActive(checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Active" />
        </FormGroup>
        <DataGrid
          density={'compact'}
          rowSelection={false}
          rows={filteredData}
          rowHeight={70}
          columns={columns}
          getRowId={(row: AdminFreeUser) => { return row.id; }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
            sorting: {
              sortModel: [{
                field: "created",
                sort: "desc"
              }]
            }
          }}
          pageSizeOptions={[5, 10, 50, 100, 1000]}
        />
      </Fragment>}
  </MainContainer>;

};

export default FreeUserList;

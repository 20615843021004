import styled from '@emotion/styled';
import { Grid } from '@mui/material';


export const MainContainer = styled(Grid)`
  padding-top:20px;
  padding-bottom:20px;
  flex-direction:column;
  color: #101010;
  `

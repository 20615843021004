import React, { useEffect } from "react";
import { CssBaseline, Container, Button, Grid, Typography } from "@mui/material";
import {  Outlet, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAppSelector, useAppDispatch } from "../../../store";
import { ECLoginState } from "../../../store/auth/interfaces";
import { loginCheck, systemNotLogged } from "../../../store/auth";

import { FooterBoldText, FooterSmallLinkText, ShopGrid,PhoneIcon,
  FooterSmallText, ShopButton, ShopIcon,Footer,BottomFooterGrid } from "./style";
import AppBar_Cabinet from "@components/AppBar/Cabinet";
type Props = {
  children?: React.ReactNode;
};
const CabinetLayout = ({ children }: Props) => {
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(authState);
    if (authState.loginState === ECLoginState.FirstStart) {
      //TODO: check token
      //dispatch((getBackendState()));
      dispatch((loginCheck()));
    
    } else
      if (authState.loginState !== ECLoginState.LoggedIn) {
        navigate('/');
      }

  }, [authState.loginState, navigate, dispatch])

  const logOutFunc = () => {
    dispatch(systemNotLogged());

  }

  if (authState.loginState === ECLoginState.FirstStart) {
    return <div>Loading...</div>
  } else {
    //const pathName = "/profile";
    return <div>
      <CssBaseline />
      <Helmet>
        <title>InstaVPN | Главная</title>
        <meta property="title" content={"InstaVPN | Главная"} />
        <meta property="description" content={`Безопасно и анонимно просматривайте веб-сайты с InstaVPN. Сохраняйте 
свои данные в тайне и наслаждайтесь неограниченным доступом к контенту из любой точки мира.`} />
        <meta property="og:title" content={"InstaVPN | Главная"} />
        <meta property="og:description" content={`Безопасно и анонимно просматривайте веб-сайты с InstaVPN. Сохраняйте 
свои данные в тайне и наслаждайтесь неограниченным доступом к контенту из любой точки мира.`} />
      </Helmet>
      <Container  maxWidth={false} style={{ marginTop: "15px" }}>
        <AppBar_Cabinet />
        <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        </Grid>
        <Outlet />
      </Container>

    </div>;
  }
};

export default CabinetLayout;

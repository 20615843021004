
import React, { Fragment, useEffect, useState } from "react";
import { MainContainer } from "./style";
import { AdminUserActivity, ECLoginState, TestUser, UserBasicInfo } from "@store/auth/interfaces";
import { useAppSelector, useAppDispatch } from "@store/index";
import { } from "@store/auth";
import { useNavigate, useParams } from "react-router-dom";
import { generateGuestToken, getAdminTransStatistic, getAdminUserActivity, sendNewTestUsers } from "@src/ApiFunctions/statFunc";
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Autocomplete, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { customFormatInTimeZone, dateComparator } from "@src/helpers";

function isValidEmail(email: string) {
  // Email validation regular expression
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}



const UserActivity = () => {
  const siteState = useAppSelector((state) => state.auth);
  const [userActivityList, setUserActivityList] = useState<AdminUserActivity[]|undefined>(undefined);
  let { userId } = useParams();
  useEffect(() => {
    const pullUserActivity = async () =>{
      getAdminUserActivity(userId?.toString() ?? "", siteState.loginToken!).then(result => {
        console.log(result)
        if (result != null) {
          setUserActivityList((result as AdminUserActivity[]));
        }
  
      })
    }
    if (siteState.loginToken!=undefined)
    {
      pullUserActivity();
    }
  }, [siteState.loginToken, userId])
  
  
  const columns: GridColDef[] = [
    {
      field: 'date', headerName: 'Date', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.date)}`
    },
    { field: 'EventType', headerName: 'Event Type', flex: 1 },
    { field: 'EventInfo', headerName: 'Event Info',   flex: 1 },
  ];

  return <MainContainer>
        <Paper style={{ marginBottom: "10px", padding: "10px" }}>
          <Typography>User Activity report</Typography>
          {/* <Button onClick={pullUserActivity} onTouchEnd={pullUserActivity} >Show activity</Button> */}
          {userActivityList===undefined  &&     <Typography>Loading.. </Typography>}
          {userActivityList && <Fragment>
            <Typography>Activity List for {userId} </Typography>
            <DataGrid
              density={'compact'}
              rowSelection={false}
              rows={userActivityList}
              rowHeight={70}
              columns={columns}
              getRowId={(row: AdminUserActivity) => { return row.date; }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 20 },
                },
                sorting:{
                  sortModel:[{
                    field:"date",
                    sort:"desc"
                  }]
                 }
              }}
              pageSizeOptions={[5, 10, 50, 100, 1000]}
            />
          </Fragment>}
        </Paper>
  </MainContainer>;

};

export default UserActivity;

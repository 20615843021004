
import React, { Fragment, useEffect, useState } from "react";
import { MainContainer } from "./style";
import {  AdminUserBehaviour, ECLoginState } from "@store/auth/interfaces";
import { useAppSelector } from "@store/index";
import {  } from "@store/auth";
import { useNavigate } from "react-router-dom";
import { getAdminTransStatistic } from "@src/ApiFunctions/statFunc";
import { DataGrid, GridColDef,GridValueGetterParams } from '@mui/x-data-grid';
import { dateComparator, customFormatInTimeZone } from "@src/helpers";


const UserBehaviour = () => {
  const siteState = useAppSelector((state) => state.auth);
  const [statData, setStatData] = useState<AdminUserBehaviour[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (siteState.loginState === ECLoginState.LoggedIn) {
      getAdminTransStatistic("UserBehaviour", siteState.loginToken!).then(result => {
        if (result!=null)
        {
          setStatData(result as AdminUserBehaviour[]);
        }

      })
    } else
    {
        navigate('/');
    }
  }, [])


  const onRowClick = (data: any ) => {
    if (data.field !== undefined && data.field ==='id') {
      window.open('/profile/AdminTools/tools/' + data.value, "_blank");
    }
  }
  const columns: GridColDef[] = [
    { field: 'id', type: 'number', headerName: 'UserId', flex: 0 },
    {
      field: 'email', headerName: 'Email|Phone', flex: 1, valueGetter: (params: GridValueGetterParams) =>
        `${params.row.email !== null ? (params.row.email) : (params.row.phone !== null ? (params.row.phone) : '-')}`
    },

    {
      field: 'created', headerName: 'Дата регистрации', flex: 1,
      sortComparator: dateComparator,
      valueGetter: (params: GridValueGetterParams) =>
        `${customFormatInTimeZone(params.row.created)}`
    },

    {
      field: 'trialStart', headerName: 'Дата запуска триала',flex:1,
    sortComparator:dateComparator,
    valueGetter: (params: GridValueGetterParams) =>
    `${params.row.trialStart!=null ? customFormatInTimeZone(params.row.trialStart):"N/A"}` },

    { field: 'planStart', headerName: 'Дата первой оплаты',flex:1,
    sortComparator:dateComparator,
    valueGetter: (params: GridValueGetterParams) =>
    `${params.row.planStart!=null ? customFormatInTimeZone(params.row.planStart):"N/A"}` },

    { field: 'payed', headerName: 'Является платящим' ,flex:1, valueGetter: (params: GridValueGetterParams) =>
    `${params.row.planStart !== null ? ('Yes') : ('No')}`},

    { field: 'planEnd', headerName: 'Дата окончания пользования',flex:1,
    sortComparator:dateComparator,
    valueGetter: (params: GridValueGetterParams) =>
    `${params.row.planEnd!=null ? customFormatInTimeZone(params.row.planEnd):"N/A"}` },

    { field: 'plan_len', headerName: 'Оплаченные месяця' ,flex:1},
  
    { field: 'success_count', headerName: 'Количество успешных подключений',flex:0 },
    { field: 'all_count', headerName: 'Количество попыток подключения',flex:0 },
    { field: 'isTest', headerName: 'isTest',flex:0 },
  ];

  return <MainContainer>
    {!statData || statData.length == 0 ?
      <div>Loading</div>
      : <Fragment>
        <DataGrid
          density={'compact'}
          rowSelection={false}
          onCellClick={onRowClick}
          rows={statData}
           rowHeight={70}
           columns={columns}
           getRowId={(row:AdminUserBehaviour)=>{return row.id;}}
           initialState={{
             pagination: {
               paginationModel: { page: 0, pageSize: 20 },
             },
             sorting:{
              sortModel:[{
                field:"created",
                sort:"desc"
              }]
             }
           }}
           pageSizeOptions={[5, 10,50,100,1000]}
         />
         </Fragment>}
  </MainContainer>;

};

export default UserBehaviour;
